import React from "react"
import Layout from "../../components/layout"
import { StaticImage } from "gatsby-plugin-image"

import SurfaceSelector from "../../components/paintingtools/surfaceselector"

import * as styles from "../../styles/pages/paintingtools.module.css"

const imgbanner = "../../images/paintingtools/pcbanner.jpg"

const CalculatorPage = () => (
	<Layout activePath='paintingtools/calculator' pageTitle="Paint Calculator" pageDescription="Not sure how much paint you need? Let us do the math for you.">
		<div className={styles.banner}>
			<StaticImage layout={"fullWidth"}  src={imgbanner} alt={"Paint Calculator"} />
		</div>

		<h1 className={"text-centered textcolor-primary "}>Paint Calculator</h1>
		<div className={"text-centered textcolor-primary "+styles.description}>
			<div>Not sure how much paint you need?</div>
			<div>Let us do the math for you.</div>
		</div>
		<SurfaceSelector selectmode={"pc"} />
	</Layout>
)

export default CalculatorPage
